import {
  INTERIOR_SPACE_TYPES,
  EXTERIOR_SPACE_TYPES,
  INTERIOR_DESIGN_STYLES,
  COLOR_PREFERENCE_INTERIOR,
  FABRIC_PREFERENCE_INTERIOR,
  EXTERIOR_DESIGN_STYLES,
  PLANT_PREFERENCE_EXTERIOR,
  PAVEMENT_PREFERENCE_EXTERIOR,
} from "@/ui-constants";

export const randomPicker = (data) => {
  return data[Math.floor(Math.random() * data.length)];
};

export const designDataPicker = (spaceType) => {
  spaceType =
    INTERIOR_SPACE_TYPES.indexOf(spaceType) >= 0 ? "Interior" : "Exterior";
  if (spaceType === "Interior") {
    return {
      // design_theme: randomPicker(INTERIOR_DESIGN_STYLES),
      masking_category: "furnishing",
      // color_and_pattern_preference: randomPicker(COLOR_PREFERENCE_INTERIOR) + "&" + randomPicker(FABRIC_PREFERENCE_INTERIOR),
    };
  } else {
    return {
      // design_theme: randomPicker(EXTERIOR_DESIGN_STYLES),
      masking_category: "landscaping",
      landscaping_preference: randomPicker(PAVEMENT_PREFERENCE_EXTERIOR) + "&" + randomPicker(PLANT_PREFERENCE_EXTERIOR),
    };
  }
};
