"use client";
import {
  CognitoUser,
  CognitoRefreshToken,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

import Cookies from "js-cookie";
import { logOutUser } from "./logout";
import { LANDING_URL } from "@/ui-constants";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_CLIENT_ID,
});

const refreshSession = async (cognitoUser, refreshToken) => {
  return new Promise((resolve, reject) => {
    const cognitoRefreshToken = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });
    cognitoUser.refreshSession(cognitoRefreshToken, (err, session) => {
      if (err) {
        reject(err);
      } else {
        resolve(session.getIdToken().getJwtToken());
      }
    });
  });
};

const auth = () => {
  return new Promise(async (resolve, reject) => {
    const idToken = Cookies.get("idToken");
    const userName = Cookies.get("username");
    const refreshToken = Cookies.get("refreshToken");

    if (idToken) {
      resolve(idToken);
    } else {
      if (userName && refreshToken) {
        const userData = {
          Username: userName,
          Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        try {
          const newToken = await refreshSession(cognitoUser, refreshToken);
          Cookies.set("idToken", newToken, {
            expires: new Date(new Date().getTime() + 58 * 60 * 1000), domain: `.${(window.location.hostname).split('.').slice(-2).join('.')}`
          });
          resolve(newToken);
        } catch (error) {
          logOutUser();
          reject(false);
        }
      } else {
        window.location = `${LANDING_URL}?logout=logout`;
        reject(false);
      }
    }
  });
};

export default auth;
