import { showLoading, hideLoading } from "@/redux/slices/loader";
import { openSnackbar } from "@/redux/slices/snackbar";
import { errorCodeToMessage } from "@/ui-constants";
export const isHandlerDisabled = (config = {}, type) => {
  if (type === "loader")
    return (config.hasOwnProperty("disableLoader") && (config?.disableLoader===true)
      ? true
      : false)
  else if (type === "snackbar")
    return (config.hasOwnProperty("disableSnackbar") && (config?.disableSnackbar===true)
      ? true
      : false)
};

export const requestHandler = (request, config) => {
  if (!isHandlerDisabled(config, "loader")) {
    config.dispatch(showLoading({ message: config.loaderMsg }));
  }
  return request;
};

export const successHandler = (response, config) => {
  if (!isHandlerDisabled(config, "loader")) {
    config.dispatch(hideLoading({}));
  }
  return response;
};

export const errorHandler = (error, config) => {
  config.dispatch(hideLoading({}));
  if (!isHandlerDisabled(config, "snackbar")) {
    if (config?.errorMsg)
      config.dispatch(openSnackbar({ message: config.errorMsg }));
    else
      config.dispatch(
        openSnackbar({
          message: error?.response?.status
            ? errorCodeToMessage[error.response.status]
            : "Something went wrong E-code(AP)",
        })
      );
  }
  return Promise.reject({ ...error });
};

// export const fetchSomeData = createAsyncThunk(
//   "solutions/fetch",
//   async (data, thunkAPI) => {
//     const response = await customAPI(
//       "get",
//       `https://fakestoreapi.com/products`,
//       {},
//       {
//         dispatch: thunkAPI.dispatch,
//         loaderMsg: "loading your data please wait",
//         errorMsg: "Failed to load messages from the queue",
//         disableLoader: false,
//         disableSnackbar: false,
//       }
//     );
//     return response;
//   }
// );
