"use client";
import {
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { LANDING_URL } from "@/ui-constants";
import Cookies from "js-cookie";



export const logOutUser = () => {
    const userPool = new CognitoUserPool({
        UserPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
        ClientId: process.env.NEXT_PUBLIC_CLIENT_ID,
      });
    const userName = Cookies.get("username");
    if(userName){
    const userData = {
        Username: userName,
        Pool: userPool,
      };
      const cognitoUser = new CognitoUser(userData);
      
    Cookies.remove('username', { path: '', domain: `.${(window.location.hostname).split('.').slice(-2).join('.')}` })
    Cookies.remove('refreshToken', { path: '', domain: `.${(window.location.hostname).split('.').slice(-2).join('.')}` })
    Cookies.remove('idToken', { domain: `.${(window.location.hostname).split('.').slice(-2).join('.')}` })
    cognitoUser.getSession((err, result)=>{
      if(result){
        cognitoUser.globalSignOut({
          onSuccess: (result) => {
           window.location = `${LANDING_URL}?logout=logout`;
          },
          onFailure: (err) => {
            window.location = `${LANDING_URL}?logout=logout`;
          },
        });
      }
      else{
       window.location = `${LANDING_URL}?logout=logout`;
      }
    });
    // cognitoUser.signOut();
  }
  {
    window.location = `${LANDING_URL}?logout=logout`;
  }
    
}