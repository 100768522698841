import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  message: "",
  status: "",
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action) => {
      const { message, status } = action.payload;
      state.isOpen = true;
      state.message = message;
      state.status = status;
    },
    closeSnackbar: (state) => {
      state.isOpen = false;
      state.message = "";
      state.status = "";
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
