import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscription: { enablePurchasePopup: false, isCloseEnabled: true },
  page_loader: false,
  payment_success: false,
  payment_failure: false,
};

const uiConfigSlice = createSlice({
  name: "ui-config",
  initialState,
  reducers: {
    enablePurchasePopup: (state, action) => {
      state.subscription.enablePurchasePopup = true;
      state.subscription.isCloseEnabled =
        action.payload?.isCloseEnabled ?? true;
    },
    disablePurchasePopup: (state) => {
      state.subscription.enablePurchasePopup = false;
      state.subscription.isCloseEnabled = true;
    },
    startPageLoader: (state) => {
      state.page_loader = true;
    },
    stopPageLoader: (state) => {
      state.page_loader = false;
    },
    setPaymentSuccess: (state) => {
      state.payment_success = true;
    },
    unsetPaymentSuccess: (state) => {
      state.payment_success = false;
    },
    setPaymentFailure: (state) => {
      state.payment_failure = true;
    },
    unsetPaymentFailure: (state) => {
      state.payment_failure = false;
    },
  },
});

export const {
  enablePurchasePopup,
  disablePurchasePopup,
  startPageLoader,
  stopPageLoader,
  setPaymentSuccess,
  unsetPaymentSuccess,
  setPaymentFailure,
  unsetPaymentFailure,
} = uiConfigSlice.actions;

export default uiConfigSlice.reducer;
