import { COLOR_MAPPING } from "@/ui-constants";

const solutionIds = {
  // SAM
  REMOVE_OBJECT: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {},
    };
  },
  SURFACE_CHANGE_WALL: (data) => {
    let changeType = data?.panel_schema?.change_type.value;

    if (changeType === "Wall paper" || data === "buisness_to_view") {
      return {
        media_id: data?.media_id,
        url: data?.url,
        space_type: data?.space_type,
        solution_name: data?.solution_name,
        mask_url: data?.mask_url,
        generation_input: {
          change_type: data?.panel_schema?.change_type?.value || "",
          color_and_pattern_preference:
            data?.panel_schema?.multiple_color_and_pattern_preference?.value ||
            "",
          pattern: data?.panel_schema?.pattern?.value || "",
          user_instructions:
            data?.panel_schema?.user_instructions?.value || "",  
        },
      };
    } else {
      return {
        media_id: data?.media_id,
        url: data?.url,
        space_type: data?.space_type,
        solution_name: data?.solution_name,
        mask_url: data?.mask_url,
        generation_input: {
          change_type: data?.panel_schema?.change_type?.value || "",
          color_and_pattern_preference:
            data?.panel_schema?.color_and_pattern_preference?.value || "",
          user_instructions:
            data?.panel_schema?.user_instructions?.value || "",  
        },
      };
    }
  },
  SURFACE_CHANGE_FLOOR: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        material_type: data?.panel_schema?.material_type?.value || "",
        material_sub_type: data?.panel_schema?.material_sub_type?.value || "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  SURFACE_CHANGE_CEILING: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        ceiling_type: data?.panel_schema?.ceiling_type?.value || "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  SURFACE_CHANGE_KITCHEN_BACKSPLASH: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        backsplash_type: data?.panel_schema?.backsplash_type?.value || "",
        backsplash_sub_type:
          data?.panel_schema?.backsplash_sub_type?.value || "",
        user_instructions:
          data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  SURFACE_CHANGE_KITCHEN_COUNTERTOP: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        countertop_type: data?.panel_schema?.countertop_type?.value || "",
        countertop_sub_type:
          data?.panel_schema?.countertop_sub_type?.value || "",
        user_instructions:
          data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  SURFACE_CHANGE_KITCHEN_APPLIANCES: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        material_type: data?.panel_schema?.material_type?.value || "",
      },
    };
  },
  SURFACE_CHANGE_KITCHEN_CABINETS: (data) => {
    let changeType = data?.panel_schema?.change_type.value;

    if (changeType === "Style") {
      let customization = data?.panel_schema?.select_customization.value;
      if (customization === "Material") {
        return {
          media_id: data?.media_id,
          url: data?.url,
          space_type: data?.space_type,
          solution_name: data?.solution_name,
          mask_url: data?.mask_url,
          generation_input: {
            design_theme: data?.panel_schema?.design_theme?.value || "",
            change_type: data?.panel_schema?.change_type?.value || "",
            // select_customization:
            //   data?.panel_schema?.select_customization?.value || "",
            material_type: data?.panel_schema?.material_type?.value || "",
            user_instructions:
            data?.panel_schema?.user_instructions?.value || "",  
          },
        };
      } else if (customization === "Color") {
        return {
          media_id: data?.media_id,
          url: data?.url,
          space_type: data?.space_type,
          solution_name: data?.solution_name,
          mask_url: data?.mask_url,
          generation_input: {
            design_theme: data?.panel_schema?.design_theme?.value || "",
            change_type: data?.panel_schema?.change_type?.value || "",
            color_and_pattern_preference:
              data?.panel_schema?.multiple_color_and_pattern_preference
                ?.value || "",
            user_instructions:
              data?.panel_schema?.user_instructions?.value || "",  
          },
        };
      }
    } else if (changeType === "Material") {
      return {
        media_id: data?.media_id,
        url: data?.url,
        space_type: data?.space_type,
        solution_name: data?.solution_name,
        mask_url: data?.mask_url,
        generation_input: {
          change_type: data?.panel_schema?.change_type?.value || "",
          material_type: data?.panel_schema?.material_type?.value || "",
          user_instructions:
          data?.panel_schema?.user_instructions?.value || "",  
        },
      };
    } else if (changeType === "Color") {
      return {
        media_id: data?.media_id,
        url: data?.url,
        space_type: data?.space_type,
        solution_name: data?.solution_name,
        mask_url: data?.mask_url,
        generation_input: {
          change_type: data?.panel_schema?.change_type?.value || "",
          color_and_pattern_preference:
            data?.panel_schema?.multiple_color_and_pattern_preference?.value ||
            "",
          user_instructions:
            data?.panel_schema?.user_instructions?.value || "",  
        },
      };
    } else {
      return {
        media_id: data?.media_id,
        url: data?.url,
        space_type: data?.space_type,
        solution_name: data?.solution_name,
        mask_url: data?.mask_url,
        generation_input: {
          change_type: "",
          select_customization: "",
          material_type: "",
          design_theme: "",
          color_and_pattern_preference: "",
          user_instructions:
          data?.panel_schema?.user_instructions?.value || "",  
        },
      };
    }
  },
  SURFACE_CHANGE_BATHROOM_WALL_TILE: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        wall_tile_type: data?.panel_schema?.wall_tile_type?.value || "",
        wall_tile_sub_type: data?.panel_schema?.wall_tile_sub_type?.value || "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  SURFACE_CHANGE_BATHROOM_COUNTERTOP: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        countertop_type: data?.panel_schema?.countertop_type?.value || "",
        countertop_sub_type:
          data?.panel_schema?.countertop_sub_type?.value || "",
        user_instructions:
          data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  SURFACE_CHANGE_BATHROOM_CABINETS: (data) => {
    let changeType = data?.panel_schema?.change_type.value;

    if (changeType === "Style") {
      let customization = data?.panel_schema?.select_customization.value;
      if (customization === "Material") {
        return {
          media_id: data?.media_id,
          url: data?.url,
          space_type: data?.space_type,
          solution_name: data?.solution_name,
          mask_url: data?.mask_url,
          generation_input: {
            design_theme: data?.panel_schema?.design_theme?.value || "",
            change_type: data?.panel_schema?.change_type?.value || "",
            // select_customization:
            //   data?.panel_schema?.select_customization?.value || "",
            material_type: data?.panel_schema?.material_type?.value || "",
            user_instructions:
            data?.panel_schema?.user_instructions?.value || "",  
          },
        };
      } else if (customization === "Color") {
        return {
          media_id: data?.media_id,
          url: data?.url,
          space_type: data?.space_type,
          solution_name: data?.solution_name,
          mask_url: data?.mask_url,
          generation_input: {
            design_theme: data?.panel_schema?.design_theme?.value || "",
            change_type: data?.panel_schema?.change_type?.value || "",
            color_and_pattern_preference:
              data?.panel_schema?.multiple_color_and_pattern_preference
                ?.value || "",
            user_instructions:
              data?.panel_schema?.user_instructions?.value || "",  
          },
        };
      }
    } else if (changeType === "Material") {
      return {
        media_id: data?.media_id,
        url: data?.url,
        space_type: data?.space_type,
        solution_name: data?.solution_name,
        mask_url: data?.mask_url,
        generation_input: {
          change_type: data?.panel_schema?.change_type?.value || "",
          material_type: data?.panel_schema?.material_type?.value || "",
          user_instructions:
          data?.panel_schema?.user_instructions?.value || "",  
        },
      };
    } else if (changeType === "Color") {
      return {
        media_id: data?.media_id,
        url: data?.url,
        space_type: data?.space_type,
        solution_name: data?.solution_name,
        mask_url: data?.mask_url,
        generation_input: {
          change_type: data?.panel_schema?.change_type?.value || "",
          color_and_pattern_preference:
            data?.panel_schema?.multiple_color_and_pattern_preference?.value ||
            "",
          user_instructions:
            data?.panel_schema?.user_instructions?.value || "",  
        },
      };
    } else {
      return {
        media_id: data?.media_id,
        url: data?.url,
        space_type: data?.space_type,
        solution_name: data?.solution_name,
        mask_url: data?.mask_url,
        generation_input: {
          change_type: "",
          select_customization: "",
          material_type: "",
          design_theme: "",
          color_and_pattern_preference: "",
          user_instructions:
          data?.panel_schema?.user_instructions?.value || "",  
        },
      };
    }
  },
  CHANGE_MATERIALS_ELEMENT: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        text_input_from_user:
          data?.panel_schema?.text_input_from_user?.value || "",
      },
    };
  }, 
  LANDSCAPING: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.panel_schema?.designing_for?.value,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        designing_for: data?.panel_schema?.designing_for?.value,
        design_theme: data?.panel_schema?.design_theme?.value || "",
        masking_category: "landscaping",
        color_and_pattern_preference:
            data?.panel_schema?.multiple_color_and_pattern_preference?.value ||
            "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  AI_LANDSCAPING: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.panel_schema?.designing_for?.value,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        designing_for: data?.panel_schema?.designing_for?.value,
        design_theme: data?.panel_schema?.design_theme?.value || "",
        masking_category: "landscaping",
        color_and_pattern_preference:
            data?.panel_schema?.multiple_color_and_pattern_preference?.value ||
            "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  LAWN_ENHANCEMENT: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: { lawn_type: data?.panel_schema?.lawn_type?.value },
    };
  },
  SKY_ENHANCEMENT: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: { sky_type: data?.panel_schema?.sky_type?.value },
    };
  },
  POOL_WATER_ENHANCEMENT: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        pool_water_type: data?.panel_schema?.pool_water_type?.value,
      },
    };
  },
  CHANGE_PATHWAYS_WALKWAYS: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        pathway_type: data?.panel_schema?.pathway_type?.value,
      },
    };
  },

  //  ADE
  AI_INTERIOR_DESIGN: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        design_theme: data?.panel_schema?.design_theme?.value || "",
        masking_category: "furnishing",
        color_and_pattern_preference:
          data?.panel_schema?.multiple_color_and_pattern_preference?.value ||
          "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",    
      },
    };
  },
  REDESIGN_FURNISHED_ROOM: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        designing_for: data?.panel_schema?.designing_for?.value || "",
        design_theme: data?.panel_schema?.design_theme?.value || "",
        masking_category: "furnishing",
        color_and_pattern_preference:
          data?.panel_schema?.multiple_color_and_pattern_preference?.value.trim() ||
          "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",    
      },
    };
  },
  REDESIGN_FURNISHED_ROOMS: (data) => {
    
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        designing_for: data?.panel_schema?.designing_for?.value || "",
        design_theme: data?.panel_schema?.design_theme?.value || "",
        masking_category: "furnishing",
        color_and_pattern_preference: COLOR_MAPPING[data?.panel_schema?.multiple_color_and_pattern_preference?.value]
           ||
          "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",    
      },
    };
  },
  OCCUPIED_TO_VACANT: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {},
    };
  },
  EMPTY_THE_SPACE: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        designing_for: data?.panel_schema?.designing_for?.value,
      },
    };
  },
  EMPTY_YOUR_SPACE: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        designing_for: data?.panel_schema?.designing_for?.value,
      },
    };
  },
  UNDER_CONSTRUCTION_EXTERIOR: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        designing_for: data?.panel_schema?.designing_for?.value,
        design_theme: data?.panel_schema?.design_theme?.value,
      },
    };
  },
  RENDER_EXTERIOR_STRUCTURES: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        designing_for: data?.panel_schema?.designing_for?.value,
        design_theme: data?.panel_schema?.design_theme?.value,
        color_and_pattern_preference:
          data?.panel_schema?.multiple_color_and_pattern_preference?.value.trim() ||
          "",
      },
    };
  },
  UNDER_CONSTRUCTION_INTERIOR: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        design_theme: data?.panel_schema?.design_theme?.value,
      },
    };
  },
  KITCHEN_REMODELING: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        design_theme: data?.panel_schema?.design_theme?.value,
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  BATHROOM_REMODELING: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        design_theme: data?.panel_schema?.design_theme?.value,
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  VIRTUAL_STAGING_EMPTY_ROOM: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.panel_schema?.designing_for?.value,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        design_theme: data?.panel_schema?.design_theme?.value,
        designing_for: data?.panel_schema?.designing_for?.value,
        masking_category: "furnishing",
        color_and_pattern_preference:
          data?.panel_schema?.multiple_color_and_pattern_preference?.value ||
          "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  FURNISH_EMPTY_ROOM: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.panel_schema?.designing_for?.value,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        design_theme: data?.panel_schema?.design_theme?.value,
        designing_for: data?.panel_schema?.designing_for?.value,
        masking_category: "furnishing",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  VIRTUAL_STAGING: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.panel_schema?.designing_for?.value,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        design_theme: data?.panel_schema?.design_theme?.value,
        designing_for: data?.panel_schema?.designing_for?.value,
        masking_category: "furnishing",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",  
      },
    };
  },
  VIRTUAL_STAGING_FURNISHED_ROOM: (data) => {
    return {
      media_id: data?.media_id,
      url: data?.url,
      space_type: data?.space_type,
      solution_name: data?.solution_name,
      mask_url: data?.mask_url,
      generation_input: {
        design_theme: data?.panel_schema?.design_theme?.value || "",
        masking_category: "furnishing",
        color_and_pattern_preference:
          data?.panel_schema?.multiple_color_and_pattern_preference?.value ||
          "",
        user_instructions:
        data?.panel_schema?.user_instructions?.value || "",
      },
    };
  },
  SURPRISE_ME: (data) => {
    return {
      design_theme: "",
      preference: "",
      masking_category: "",
    };
  },
};

const genObj = {
  media_id: "",
  url: "",
  mask_url: "",
  space_type: "",
  solution_name: "",
};

export const viewToBuisnessTransformer = (solution_id, data) => {
  // let solutionObj = { ...solutionIds[solution_id](data) };
  // let newObj = { ...genObj};
  // newObj.media_id = data?.media_id;
  // newObj.url = data?.url;
  // newObj.space_type = data?.space_type;
  // newObj.solution_name = data?.solution_name;
  // newObj.generation_input = { ...solutionIds[solution_id](data) };

  // if (data?.mask_url) {
  //   newObj.mask_url = data?.mask_url;
  // } else {
  //   delete newObj.mask_url;
  // }

  // Object.keys(newObj).forEach((key) => {
  //   if (!newObj[key]) {
  //     delete newObj[key];
  //   }
  // });
  return solutionIds[solution_id](data);
};

export const buisnessToViewTransformer = (solution_id, data) => {
  let solutionObj = { ...solutionIds[solution_id]("buisness_to_view") };

  let emptyValueObj = {};
  Object.keys(solutionObj.generation_input).forEach((key) => {
    emptyValueObj[key] = "";
  });
  return emptyValueObj;
};