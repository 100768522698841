import {
  NO_MASK_SEG_LABELS_EXTERIOR,
  NO_MASK_SEG_LABELS_INTERIOR,
  INTERIOR_SPACE_TYPES,
  EXTERIOR_SPACE_TYPES,
} from "@/ui-constants";
import { uploadImageAPINoStore } from "./uploaderAPINoStore";
export const mergeMaskImages = async (
  mask_output,
  space_type,
  auto_mask_exclusion_filter
) => {
  const canvas = document.createElement("canvas");
  const imageUrls = [];
  const maskNames = [];
  space_type =
    INTERIOR_SPACE_TYPES.indexOf(space_type) >= 0 ? "interior" : "exterior";
  const filterLabels = auto_mask_exclusion_filter[space_type];
  // space_type === "interior"
  //   ? auto_mask_exclusion_filter["interior"]
  //   : auto_mask_exclusion_filter["exterior"];

  for (let item of mask_output) {
    if (filterLabels.indexOf(item.name) < 0) {
      imageUrls.push(item.url);
      maskNames.push(item.name);
    }
  }

  if (imageUrls.length === 0) {
    return {url:"",path:""};
  }

  const imgHW = await getImgOrgHW(imageUrls[0]);

  function getImgOrgHW(imgUrl) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = function () {
        resolve({ h: this.height, w: this.width });
      };
      img.src = imgUrl;
    });
  }

  canvas.width = imgHW.w;
  canvas.height = imgHW.h;
  const ctx = canvas.getContext("2d");

  async function drawMaskForSegmentsTags() {
    return new Promise(async (resolve) => {
      for (let item of imageUrls) {
        let image = document.createElement("img");
        var image_url = item;
        image.src = await fileToDataUri(image_url);
        image.addEventListener("load", () => {
          ctx.drawImage(image, 0, 0, imgHW.w, imgHW.h);
        });
      }

      resolve(true);
    });
  }
   await drawMaskForSegmentsTags()

  async function fileToDataUri(field) {
    try {
      // Fetch the image
      const response = await fetch(field); // Check if the request was successful (status code 200)

      if (response.ok) {
        // Read the image data as a Blob
        const imageBlob = await response.blob(); // Convert the Blob to a data URI

        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(imageBlob);
        });
      } else {
        console.error("Failed to fetch image:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  }
  function delay(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  await delay(1000); 
  const imageData = canvas.toDataURL("image/png");
  const imageBlob = await fetch(imageData).then((it) => it.blob());

  const imageFile = new File([imageBlob], "fileName.png", {
    type: "image/png",
    lastModified: new Date(),
  });

  const uploadedImage = await uploadImageAPINoStore(
    imageFile,
    "mask",
    0,
    "Analyzing mask..."
  );

  return uploadedImage;
};

export const getStaticWhiteMask = async (input_image) => {
  function getImgOrgHW(imgUrl) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = function () {
        resolve({ h: this.height, w: this.width });
      };
      img.src = imgUrl;
    });
  }

  const imgHW = await getImgOrgHW(input_image);

  const canvas = document.createElement('canvas')
  canvas.width = imgHW.w
  canvas.height = imgHW.h
  
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = 'black';
  ctx.fillRect(0, 0, imgHW.w, imgHW.h);
  ctx.fillStyle = 'white'
  ctx.fillRect(0, 5, imgHW.w, imgHW.h)

  const imageData = canvas.toDataURL("image/png");

  const imageBlob = await fetch(imageData).then((it) => it.blob());

  const imageFile = new File([imageBlob], "fileName.png", {
    type: "image/png",
    lastModified: new Date(),
  });

  const uploadedImage = await uploadImageAPINoStore(
    imageFile,
    "mask",
    0,
    "Analyzing mask..."
  );

  return uploadedImage;
};
