import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customAPI } from "@/http";
import { hideLoading, showLoading } from "../loader";
import { mergeMaskImages } from "@/utils/maskToImage";
import { openSnackbar } from "../snackbar";
import { designDataPicker, randomPicker } from "@/utils/randomPicker";
import {
  GENERATION_FETCH_TIMEOUT,
  HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
  INTERIOR_DESIGN_STYLES,
  MASK_FETCH_TIMEOUT,
} from "@/ui-constants";
import { COLOR_OPTIONS } from "@/ui-constants/solutions-schema/constants";
import { INTERIOR_VS_THEMES as surpriseMeThemes } from "@/ui-constants/solutions-schema/constants";
import { isShowProgressPopup, isShowTimeoutPopup } from "../commonPopup";

const exteriorRoomTypes = [
  { label: "Backyard", value: "backyard" },
  { label: "Garden/landscaping", value: "garden" },
  { label: "Outdoor living", value: "outdoor living" },
  { label: "Poolside", value: "swimming pool" },
  { label: "Deck/patio", value: "deck/patio" },
];

// Colors
const surpriseMeColors = COLOR_OPTIONS;

// random select color combination
const randomColor = [
  "Mustard Yellow, Bright Yellow, Pale Purple, Indigo Blue",
  "Cyan, White, Beige, Bright Yellow",
  "Grey, Dull Blue, White, Lime Green",
  "Brown, Beige, White, Sage Green",
  "Pale Purple, Beige, White, Brown",
  "Pink, White, Grey, Dark Grey",
  "Dark Grey, Brown, Indigo Blue, White",
  "Beige, White, Forest Green, Dull Blue",
  "Lime Green, Dull Red, Beige, Indigo Blue",
  "White, Beige, Grey, Dark Grey",
  "White, Grey, Cyan, Brown",
  "Brown, Forest Green, Beige, Dull Red",
  "White, Grey, Beige, Pale Purple",
  "Brown, Dull Red, Beige, White",
  "Grey, Beige, White, Sage Green",
  "White, Cyan, Beige, Dull Blue",
  "Bright Yellow, Indigo Blue, Mustard Yellow, Pink",
  "Cyan, Bright Yellow, White, Dull Blue",
  "Lime Green, Dull Blue, White, Mustard Yellow",
  "Forest Green, Dull Red, Beige, Bright Yellow",
  "Pale Purple, Mustard Yellow, White, Bright Yellow",
  "Pink, Indigo Blue, White, Bright Yellow",
  "Dark Grey, Bright Yellow, Dull Blue, Brown",
  "Dull Blue, Beige, White, Sage Green",
  "Lime Green, Dull Blue, Mustard Yellow, Indigo Blue",
  "White, Cyan, Dull Blue, Pale Purple",
  "White, Lime Green, Cyan, Dull Red",
  "Brown, Dull Red, Sage Green, Bright Yellow",
  "White, Pale Purple, Cyan, Grey",
  "White, Dull Red, Bright Yellow, Brown",
  "Beige, Dull Blue, White, Bright Yellow",
  "White, Bright Yellow, Cyan, Sage Green",
];

const initialState = {
  page_transition: {
    upload: { uploaded: false },
    projects: { created: false },
    results: { generation: false },
    view_results: { variations: false, redesign: false },
    not_found: { is_404: false },
  },
  isLoading: false,
  projectName: "my test project",
  loading: false,
  data: {
    solution_name: "surprise-me",
    solution_id: "SURPRISE_ME",
    projectName: "my test project",
    space_options: [
      { label: "Bedroom", value: "bedroom" },
      {
        label: "Living room/family room/lounge",
        value: "living room/family room/lounge",
      },
      { label: "Kids room", value: "kids room" },
      { label: "Nursery", value: "nursery" },
      { label: "Kitchen", value: "kitchen" },
      { label: "Bathroom/ensuite", value: "bathroom/ensuite" },
      { label: "Dining", value: "dining" },
      { label: "Foyer", value: "foyer" },
      { label: "Games area/rumpus room", value: "games area/rumpus room" },
      { label: "Hobby/craft room", value: "hobby/craft room" },
      { label: "Bar", value: "bar" },
      { label: "Laundry", value: "laundry" },
      { label: "Media room", value: "media room" },
      { label: "Pantry", value: "pantry" },
      { label: "Single room studio/unit", value: "single room studio/unit" },
      { label: "Study", value: "study" },
      { label: "Sunroom", value: "sunroom" },
    ],
    upload_space_type_options: {
      is_space: true,
      options: ["Empty", "Furnished"],
      allowed_types: [true],
      error_msg: "Not allowed",
      is_redirect: true,
      redirect_solution: "VS_EMPTY_ROOM",
    },
    space_type: "",
    img: "",
    masked_url: "",
    mask_arr: [],
    results: [],
    side_panel_schema: {
      design_theme: {
        type: "picker",
        label: "Select theme",
        name: "theme",
        path: "design_theme",
        value: "",
        temp_value: "",
        info_enabled: false,
        info_txt: "",
        options: surpriseMeThemes,
        dependent_on: "",
        required: true,
        error_msg: "Please select theme",
      },
      multiple_color_and_pattern_preference: {
        type: "multiple_picker_with_string",
        min: 2,
        max: 4,
        label: "Color preferences",
        name: "Color preferences",
        path: "color_and_pattern_preference",
        value: "",
        temp_value: "",
        info_enabled: true,
        info_txt:
          "Click on the button given below or type your preferred color combination in the text box.",
        options: surpriseMeColors,
        dependent_on: "",
        required: true,
        error_msg: "Please select color preference",
      },
    },
    upload_screen: {
      main_text: "Upload an image of furnished room",
      helper_text: `Drag and drop to upload or browse image. PNG, JPEG, JPG, HEIC & HEIF files are allowed. No larger than 25MB.`,
      cta_text: "Upload image",
      edu_url: HOW_TO_CLICK_A_PHOTO_YOUTUTBE_LINK,
    },
    results_screen: {
      component: "withpanel",
      helper_text:
        "Click on the generated images to view, share, download or modify.",
      cta_text: "Generate new design",
      cta_helper_text: "",
    },
    generations_data: [],
    generation_id: "",
    project_id: "",
    media_id: "",
    auto_mask_exclusion_filter: {
      interior: [
        "wall",
        "floor",
        "ceiling",
        "fireplace",
        "windowpane",
        "stairway",
        "step",
        "stairs",
        "door",
        "railing;rail",
        "wardrobe",
        "refrigerator",
        "hood",
        "microwave",
        "countertop",
        "kitchen",
        "stove",
        "dishwasher",
        "sink",
        "oven",
        "curtain",
        "blind",
      ],
      exterior: [],
    },
  },
  error: null,
};

export const surpriseMeApiThunk = createAsyncThunk(
  "surprise-me/surpriseMeApiThunk",
  async (data, thunkAPI) => {
    try {
      // thunkAPI.dispatch(showLoading({ message: "creating project..." }));
      let uploadUrl = data.media.url;
      let space_type = data.media.space_type;
      let existingProjectId = data?.project_id || "";
      let projectId = "";
      let theme = existingProjectId
        ? data.theme
        : randomPicker(surpriseMeThemes).value;
      let color = existingProjectId ? data.color : randomPicker(randomColor);
      let solution_name = data.media.solution_name;
      let mediaId = data.mediaId;
      let auto_mask_exclusion_filter = data.auto_mask_exclusion_filter;

      if (!existingProjectId) {
        const project = await customAPI("post", `/project`, data.project, {
          dispatch: thunkAPI.dispatch,
          // disableLoader: true,
          loaderMsg: "Creating new project",
        });
        projectId = project._id;
      } else {
        projectId = existingProjectId;
      }

      if (!mediaId) {
        const media = await customAPI(
          "post",
          `/media`,
          { project_id: projectId, ...data.media },
          {
            dispatch: thunkAPI.dispatch,
            // disableLoader: true,
            loaderMsg: "Creating media...",
          }
        );

        mediaId = media._id;
      }

      // const maskJob = await customAPI(
      //   "patch",
      //   `/media/mask/${mediaId}`,
      //   { url: uploadUrl },
      //   {
      //     dispatch: thunkAPI.dispatch,
      //     loaderMsg: "Analyzing image",
      //   }
      // );

      // thunkAPI.dispatch(showLoading({ message: "Identifying masks" }));
      const t1 = performance.now();
      const getMask = async () => {
        try {
          const response = await customAPI(
            "get",
            `/media/mask/${mediaId}`,
            {},
            {
              dispatch: thunkAPI.dispatch,
              disableLoader: true,
            }
          );
          const t2 = performance.now();
          if (t2 - t1 > MASK_FETCH_TIMEOUT) {
            thunkAPI.dispatch(hideLoading({}));
            thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
            thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
            return;
          }
          if (response) {
            if (response.job_status === "error") {
              thunkAPI.dispatch(hideLoading({}));
              thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
              thunkAPI.dispatch(
                openSnackbar({
                  message: "Something went wrong. Please try again E-code(FME)",
                  status: "error",
                })
              );
              return;
            }
            if (response.job_status === "done") {
              if (response.job_status === "error") {
                thunkAPI.dispatch(hideLoading({}));
                thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
                thunkAPI.dispatch(
                  openSnackbar({
                    message:
                      "Something went wrong. Please try again E-code(FME)",
                    status: "error",
                  })
                );
              }
              return response; // Return the success response
            }
          }
        } catch (error) {
          throw new Error(error);
        }

        // Retry after the specified interval
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return getMask(); // Recursive call
      };

      // const masks = await getMask();

      // let selectedMask = "";
      // let maskUrl = await mergeMaskImages(
      //   masks.mask_output,
      //   space_type,
      //   auto_mask_exclusion_filter
      // );
      // let selectedMask = maskUrl.url;

      const generationJob = await customAPI(
        "post",
        `/generate`,
        {
          media_id: mediaId,
          url: uploadUrl,
          mask_url: uploadUrl,
          solution_name: solution_name,
          space_type: space_type,
          generation_input: {
            masking_category: "furnishing",
            design_theme: theme,
            color_and_pattern_preference: color,
            ...designDataPicker(space_type),
          },
        },
        {
          dispatch: thunkAPI.dispatch,
          disableLoader: false,
          loaderMsg: "Generating design...",
        }
      );

      let generationId = generationJob._id;
      let eta = generationJob.eta;

      thunkAPI.dispatch(isShowProgressPopup({ isShow: true, time: eta }));

      // thunkAPI.dispatch(showLoading({ message: "Generating designs..." }));
      const t3 = performance.now();
      const getGenerations = async () => {
        try {
          const response = await customAPI(
            "get",
            `/generate/${generationId}`,
            {},
            {
              dispatch: thunkAPI.dispatch,
              disableLoader: true,
            }
          );
          const t4 = performance.now();
          if (t4 - t3 > GENERATION_FETCH_TIMEOUT) {
            thunkAPI.dispatch(hideLoading({}));
            thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
            thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
            return;
          }
          if (response) {
            if (response.job_status === "error") {
              thunkAPI.dispatch(hideLoading({}));
              thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
              thunkAPI.dispatch(
                openSnackbar({
                  message: "Something went wrong. Please try again E-code(GE)",
                  status: "error",
                })
              );
              return;
            }
            if (response.job_status === "done") {
              return response; // Return the success response
            }
          }
        } catch (error) {
          throw new Error(error);
        }

        // Retry after the specified interval
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return getGenerations(); // Recursive call
      };

      const generations = await getGenerations();

      thunkAPI.dispatch(isShowProgressPopup({ isShow: true, time: 0 }));
      await new Promise((resolve) => setTimeout(resolve, 500));

      let resultImgUrls = generations.generation_output_ids.map((el) => {
        return el.output_url;
      });

      return {
        mediaId,
        maskJob: "123",
        masks: ["", ""],
        generationJob,
        generations,
        masked_url: uploadUrl,
        results: resultImgUrls,
        existingProjectId: existingProjectId,
        projectId: projectId,
        theme,
        color,
      };
    } catch (error) {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      throw new Error(error, "123");
    } finally {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
    }
  }
);

export const regenerateSurpriseMeApiThunk = createAsyncThunk(
  "surprise-me/regenerateSurpriseMeApiThunk",
  async (data, thunkAPI) => {
    try {
      let uploadUrl = data.uploadUrl;
      let mask_url = data.mask_url;
      let mediaId = data.mediaId;
      let roomType = data.roomType;
      let theme = data.theme;
      let color = data.color;
      let solution_name = data.solution_name;

      const generationJob = await customAPI(
        "post",
        `/generate`,
        {
          media_id: mediaId,
          url: uploadUrl,
          mask_url: mask_url,
          solution_name: solution_name,
          // generation_count: 3,
          space_type: roomType,
          generation_input: {
            masking_category: "furnishing",
            design_theme: theme,
            color_and_pattern_preference: color,
            ...designDataPicker(roomType),
          },
        },
        {
          dispatch: thunkAPI.dispatch,
          disableLoader: false,
          loaderMsg: "Generating design",
        }
      );

      let generationId = generationJob._id;
      let eta = generationJob.eta;

      thunkAPI.dispatch(isShowProgressPopup({ isShow: true, time: eta }));

      // thunkAPI.dispatch(showLoading({ message: "Generating designs..." }));
      const t1 = performance.now();
      const getGenerations = async () => {
        try {
          const response = await customAPI(
            "get",
            `/generate/${generationId}`,
            {},
            {
              dispatch: thunkAPI.dispatch,
              disableLoader: true,
            }
          );
          const t2 = performance.now();
          if (t2 - t1 > GENERATION_FETCH_TIMEOUT) {
            thunkAPI.dispatch(hideLoading({}));
            thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
            thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
            return;
          }
          if (response) {
            if (response.job_status === "error") {
              thunkAPI.dispatch(hideLoading({}));
              thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
              thunkAPI.dispatch(
                openSnackbar({
                  message: "Something went wrong. Please try again E-code(GE)",
                  status: "error",
                })
              );
              return;
            }
            if (response.job_status === "done") {
              // igone this block of code
              if (response.job_status === "error") {
                thunkAPI.dispatch(hideLoading({}));
                thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
                thunkAPI.dispatch(
                  openSnackbar({
                    message:
                      "Something went wrong. Please try again E-code(GE)",
                    status: "error",
                  })
                );
              }
              return response; // Return the success response
            }
          }
        } catch (error) {
          throw new Error(error);
        }

        // Retry after the specified interval
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return getGenerations(); // Recursive call
      };

      const generations = await getGenerations();

      thunkAPI.dispatch(isShowProgressPopup({ isShow: true, time: 0 }));
      await new Promise((resolve) => setTimeout(resolve, 500));

      let resultImgUrls = generations.generation_output_ids.map((el) => {
        return el.output_url;
      });

      return {
        generationJob,
        generations,
        results: resultImgUrls,
      };
    } catch (error) {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      throw new Error(error);
    } finally {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
    }
  }
);

export const createMediaSurpriseMeApiThunk = createAsyncThunk(
  "surprise-me/createMediaSurpriseMeApiThunk",
  async (data, thunkAPI) => {
    try {
      const media = await customAPI(
        "post",
        `/media`,
        { ...data },
        {
          dispatch: thunkAPI.dispatch,
          // disableLoader: true,
          loaderMsg: "Uploading image",
        }
      );

      let mediaId = media._id;
      return { mediaId };
    } catch (error) {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      throw new Error(error);
    } finally {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
    }
  }
);

export const getMediaSurpriseMeApiThunk = createAsyncThunk(
  "surprise-me/getMediaSurpriseMeApiThunk",
  async (data, thunkAPI) => {
    try {
      let projectId = data.projectId || "";
      let mediaId = data.mediaId || "";
      let generationId = data.generationId || "";

      const media = await customAPI(
        "get",
        `/media/${mediaId}`,
        {},
        {
          dispatch: thunkAPI.dispatch,
          // disableLoader: true,
          loaderMsg: "Fetching media...",
        }
      );

      let is_404 = false;
      if (
        (media?.solution_name && media?.solution_name !== "SURPRISE_ME") ||
        (media?.project_id?._id &&
          projectId &&
          media?.project_id?._id != projectId)
      ) {
        return { is_404: true };
      }

      // let mask_output = media.mask_output;
      let space_type = media.space_type;
      let img = media.url;
      let theme = "";
      let color = "";
      let mask_url = "";

      if (generationId && media?.generation_request_ids.length > 0) {
        is_404 = true;
        for (const obj of media.generation_request_ids) {
          if (generationId === obj._id) {
            mask_url = obj.mask_url[0];
            space_type = obj.space_type;
            theme = obj.generation_input.design_theme;
            color = obj.generation_input.color_and_pattern_preference;
            is_404 = false;
            break;
          }
        }
      }

      if (is_404) {
        return { is_404: true };
      }

      if (!generationId) {
        return {
          projectId,
          mediaId,
          img,
          space_type,
          is_404,
        };
      }

      const generation = await customAPI(
        "get",
        `/generate/${generationId}`,
        {},
        {
          dispatch: thunkAPI.dispatch,
          // disableLoader: true,
          loaderMsg: "Generating designs...",
        }
      );

      let job_status = media.job_status;

      // if (job_status !== "done") {
      //   thunkAPI.dispatch(
      //     openSnackbar({
      //       message: "This media has not been processed",
      //       status: "error",
      //     })
      //   );
      //   return {is_404 : false}
      // }

      let results = generation.generation_output_ids.map((el) => el.output_url);

      return {
        projectId,
        mediaId,
        generationId,
        is_404,
        img,
        results,
        mask_url,
        space_type,
        theme,
        color,
        generations: generation,
      };
    } catch (error) {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      throw new Error(error);
    } finally {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
    }
  }
);

const surpriseMeSlice = createSlice({
  name: "surprise-me",
  initialState,
  reducers: {
    setProjectName: (state, action) => {
      state.projectName = action.payload.projectName;
    },
    setPanelValues: (state, action) => {
      const { type, value } = action.payload;
      state.data.side_panel_schema[type]["value"] = value;
    },
    setEnableMask: (state, action) => {
      const { value } = action.payload;
      state.data.side_panel_enabled = value;
    },
    setUploadImgAndSpaceType: (state, action) => {
      const { url, spaceType } = action.payload;
      state.data.img = url;
      state.data.space_type = spaceType;
    },
    setResultImgs: (state, action) => {
      const { results } = action.payload;
      state.data.results = results;
    },
    setMaskedUrl: (state, action) => {
      const { url } = action.payload;
      state.data.masked_url = url;
    },
    resetPageTransitions: (state, action) => {
      state.page_transition = {
        upload: { uploaded: false },
        projects: { created: false },
        results: { generation: false },
        view_results: { variations: false, redesign: false },
        not_found: { is_404: false },
      };
    },
    setIsFavorite: (state, action) => {
      state.data.generations_data[action.payload.generationId].is_favourite =
        action.payload.favorite;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(surpriseMeApiThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.project_id = action.payload?.projectId;
      state.data.media_id = action.payload?.mediaId;
      state.data.generation_id = action.payload?.generationJob._id;
      state.data.generations_data =
        action.payload?.generations.generation_output_ids;

      state.data.masked_url = action.payload?.masked_url;
      state.data.results = action.payload?.results;
      state.data.side_panel_schema.design_theme.value = action.payload?.theme;
      state.data.side_panel_schema.multiple_color_and_pattern_preference.value =
        action.payload?.color;

      Object.keys(state.data.side_panel_schema).forEach((key) => {
        state.data.side_panel_schema[key]["temp_value"] =
          state.data.side_panel_schema[key]["value"];
      });

      if (action.payload?.existingProjectId) {
        state.page_transition.view_results.variations = true;
      } else {
        state.page_transition.projects.created = true;
      }
    });
    builder.addCase(regenerateSurpriseMeApiThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.generation_id = action.payload?.generationJob._id;
      state.data.generations_data =
        action.payload?.generations.generation_output_ids;

      Object.keys(state.data.side_panel_schema).forEach((key) => {
        state.data.side_panel_schema[key]["temp_value"] =
          state.data.side_panel_schema[key]["value"];
      });

      state.data.results = action.payload?.results;
    });
    builder.addCase(
      createMediaSurpriseMeApiThunk.fulfilled,
      (state, action) => {
        state.loading = false;
        state.data.media_id = action.payload?.mediaId;
        state.page_transition.upload.uploaded = true;
      }
    );
    builder.addCase(getMediaSurpriseMeApiThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.page_transition.not_found.is_404 =
        action.payload?.is_404 || state.page_transition.not_found.is_404;
      state.data.project_id =
        action.payload?.projectId || state.data.project_id;
      state.data.media_id = action.payload?.mediaId || state.data.media_id;
      state.data.generation_id =
        action.payload?.generationId || state.data.generation_id;
      state.data.img = action?.payload?.img || state.data.img;
      state.data.space_type =
        action?.payload?.space_type || state.data.space_type;
      state.data.results = action?.payload?.results || state.data.results;
      state.data.masked_url =
        action?.payload?.mask_url || state.data.masked_url;
      state.data.generations_data =
        action?.payload?.generations?.generation_output_ids ||
        state.data.generations_data;
      state.data.side_panel_schema.design_theme.value =
        action?.payload?.theme ||
        state.data.side_panel_schema.design_theme.value;
      state.data.side_panel_schema.multiple_color_and_pattern_preference.value =
        action?.payload?.color ||
        state.data.side_panel_schema.multiple_color_and_pattern_preference
          .value;
      Object.keys(state.data.side_panel_schema).forEach((key) => {
        state.data.side_panel_schema[key]["temp_value"] =
          state.data.side_panel_schema[key]["value"];
      });
    });
  },
});

export const {
  setProjectName,
  setPanelValues,
  setEnableMask,
  setUploadImgAndSpaceType,
  setResultImgs,
  setMaskedUrl,
  resetPageTransitions,
  setIsFavorite,
} = surpriseMeSlice.actions;

export default surpriseMeSlice.reducer;
