import { CDN_URL } from "@/ui-constants/";
export const presigned2Cdn = (url, media = "media") => {
  const pattern = /\/([^/?]+)(\?|$)/;
  const matches = url.match(pattern);
  if (matches && matches.length > 1) {
    return {
      url: `${CDN_URL}${
        process.env.NEXT_PUBLIC_ENV !== "prod" ? "dev" : "prod"
      }/${media}/${matches[1]}`,
      path: matches[1],
    };
  } else {
    return null;
  }
};

export const path2Cdn = (path, media = "media") =>
  `${CDN_URL}${
    process.env.NEXT_PUBLIC_ENV !== "prod" ? "dev" : "prod"
  }/${media}/${path}`;
