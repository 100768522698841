export const trimValues = (obj) => {
    if (typeof obj !== 'object') return obj;
    
    const newObj = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            newObj[key] = typeof value === 'string' ? value.trim() : trimValues(value);
        }
    }
    
    return newObj;
}