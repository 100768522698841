import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customAPI } from "@/http";
import {
  GENERATION_FETCH_TIMEOUT,
  MASK_FETCH_TIMEOUT,
  REVERSE_SOLUTION_SCHEMA_MAPPING,
  SOLUTIONS_SCHEMA,
  SOLUTION_SCHEMA_MAPPING,
  defaultFurnishingType,
} from "@/ui-constants";
import { hideLoading, showLoading } from "../loader";
import { getStaticWhiteMask, mergeMaskImages } from "@/utils/maskToImage";
import { openSnackbar } from "../snackbar";
import {
  buisnessToViewTransformer,
  viewToBuisnessTransformer,
} from "@/utils/viewBuisnessTransformer";
import { isShowProgressPopup, isShowTimeoutPopup } from "../commonPopup";
import { useAppSelector } from "@/redux/hooks";
import { trimValues } from "@/utils/trimValues";

const initialState = {
  page_transition: {
    upload: { uploaded: false },
    projects: { created: false },
    dp: { generation: false },
    mask: { save: false },
    results: { generation: false },
    view_results: { variations: false, redesign: false },
    not_found: { is_404: false },
  },
  isLoading: false,
  projectName: "my test project",
  loading: false,
  previousFurnishedStatus: {
    is_furnished: "NA",
    solution_id: "NA",
  },
  data: {
    project_name: "New project",
  },
  error: null,
};

export const createNewProjectThunk = createAsyncThunk(
  "solutions/createNewProjectThunk",
  async (data, thunkAPI) => {
    try {
      let uploadUrl = data?.media?.url;
      let space_type = data?.media?.space_type;
      let existingProjectId = data?.project_id ;
      let projectId = "";
      let designFurther = data?.designFurther || false;
      let maskType = data?.maskType;
      let mediaId = data?.mediaId;
      let auto_mask_exclusion_filter = data?.auto_mask_exclusion_filter;
      let pre_fetch_mask = data?.pre_fetch_mask;
      let solution = data?.solution;
      let get_static_mask = SOLUTIONS_SCHEMA[solution]?.["get_static_mask"] || "";
      if (!designFurther) {
        if (!existingProjectId) {
          const project = await customAPI("post", `/project`, data.project, {
            dispatch: thunkAPI.dispatch,
            disableLoader: false,
            loaderMsg: "Creating new project",
          });
          projectId = project._id;
        } else {
          const project = await customAPI(
            "patch",
            `/media/${mediaId}`,
            { project_id: existingProjectId },
            {
              dispatch: thunkAPI.dispatch,
              disableLoader: false,
              loaderMsg: "Adding to the project",
            }
          );
          projectId = existingProjectId;
        }
      }

      if (!projectId) {
        projectId = existingProjectId;
      }

      if (!mediaId) {
        let media_req_body = { project_id: projectId, ...data.media };

        const current_furnish_status =
          thunkAPI.getState().solutions.previousFurnishedStatus.is_furnished;
        const current_solution = data.current_solution_name;
        if (current_solution) {
          if (
            defaultFurnishingType[current_solution] == "NA" &&
            current_furnish_status != "NA"
          ) {
            thunkAPI.dispatch(
              setIsFurnished({
                is_furnished: current_furnish_status,
              })
            );
            media_req_body = {
              ...media_req_body,
              is_furnished: current_furnish_status,
            };
          } else if (defaultFurnishingType[current_solution] == null) {
            thunkAPI.dispatch(
              setIsFurnished({
                is_furnished: "NA",
              })
            );
          } else {
            thunkAPI.dispatch(
              setIsFurnished({
                is_furnished: defaultFurnishingType[current_solution],
              })
            );
            media_req_body = {
              ...media_req_body,
              is_furnished: defaultFurnishingType[current_solution],
            };
          }
        }
        const media = await customAPI("post", `/media`, media_req_body, {
          dispatch: thunkAPI.dispatch,
          disableLoader: false,
          loaderMsg: "Analyzing image",
        });
        mediaId = media._id;
        thunkAPI.dispatch(
          setPreviousSolution({
            solution_id: data.media.solution_name,
          })
        );
      }

      // if (!pre_fetch_mask && get_static_mask) {
      //   thunkAPI.dispatch(showLoading({ message: "Identifying masks" }));
      //   let maskUrl = await getStaticWhiteMask(uploadUrl);
      //   maskUrl = maskUrl.url;
      //   return {
      //     designFurther,
      //     maskUrl,
      //     mediaId,
      //     projectId,
      //   };
      // }

      if (!pre_fetch_mask) {
        return {
          designFurther,
          mediaId,
          projectId,
        };
      }
      const maskJob = await customAPI(
        "patch",
        `/media/mask/${mediaId}`,
        { url: uploadUrl },
        {
          dispatch: thunkAPI.dispatch,
          loaderMsg: "Analyzing image",
        }
      );

      // thunkAPI.dispatch(showLoading({ message: "Identifying masks" }));
      const t1 = performance.now();
      const getMask = async () => {
        try {
          const response = await customAPI(
            "get",
            `/media/mask/${mediaId}`,
            {},
            {
              dispatch: thunkAPI.dispatch,
              disableLoader: true,
            }
          );
          const t2 = performance.now();
          if (t2 - t1 > MASK_FETCH_TIMEOUT) {
            thunkAPI.dispatch(hideLoading({}));
            thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
            thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
            return;
          }
          if (response) {
            if (response.job_status === "error") {
              thunkAPI.dispatch(hideLoading({}));
              thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
              thunkAPI.dispatch(
                openSnackbar({
                  message: "Something went wrong. Please try again E-code(FME)",
                  status: "error",
                })
              );
              return;
            }
            if (response.job_status === "done") {
              return response; // Return the success response
            }
          }
        } catch (error) {
          throw new Error(error);
        }

        // Retry after the specified interval
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return getMask(); // Recursive call
      };

      const masks = await getMask();
      // let npy_url =
      //   masks.mask_output.length === 1 ? masks.mask_output[0].url : "";

      // let maskUrl = "";

      // if (!npy_url) {
      //   // let selectedMask = "";
      //   maskUrl = await mergeMaskImages(
      //     masks.mask_output,
      //     space_type,
      //     auto_mask_exclusion_filter
      //   );
      //   maskUrl = maskUrl.url;
      // }

      return {
        mediaId,
        maskUrl:masks.combined_mask_url,
        designFurther,
        maskType,
        projectId,
        npy_url,
      };
    } catch (error) {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      throw new Error(error);
    } finally {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
    }
  }
);

export const createGenerationThunk = createAsyncThunk(
  "solutions/createGenerationThunk",
  async (data, thunkAPI) => {
    try {
      
      const isRetry = data.isRetry || false;
      const space_type = data.space_type;
      const solution_name = data.solution_name;
      
      thunkAPI.dispatch(isShowProgressPopup({ isShow: true, time: null, title:data.popTitle }));

      let bodyObj = viewToBuisnessTransformer(solution_name, data);
      let trimObj  = trimValues(bodyObj);
      const generationJob = await customAPI("post", `/generate`, trimObj, {
        dispatch: thunkAPI.dispatch,
        disableLoader: true,
        loaderMsg: data.popTitle || "Generating design",
      });

      let generationId = generationJob._id;
      let eta = generationJob.eta;

      thunkAPI.dispatch(isShowProgressPopup({ isShow: true, time: eta, title:data.popTitle }));
      // thunkAPI.dispatch(showLoading({ message: "Generating designs..." }));
      const t3 = performance.now();
      const getGenerations = async () => {
        try {
          const response = await customAPI(
            "get",
            `/generate/${generationId}`,
            {},
            {
              dispatch: thunkAPI.dispatch,
              disableLoader: true,
            }
          );
          const t4 = performance.now();
          if (t4 - t3 > GENERATION_FETCH_TIMEOUT) {
            thunkAPI.dispatch(hideLoading({}));
            thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
            thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
            return;
          }
          if (response) {
            if (response.job_status === "error") {
              thunkAPI.dispatch(hideLoading({}));
              thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
              thunkAPI.dispatch(
                openSnackbar({
                  message: "Something went wrong. Please try again E-code(GE)",
                  status: "error",
                })
              );
              return;
            }
            if (response.job_status === "done") {
              return response; // Return the success response
            }
          }
        } catch (error) {
          throw new Error(error);
        }

        // Retry after the specified interval
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return getGenerations(); // Recursive call
      };

      const generations = await getGenerations();

      thunkAPI.dispatch(isShowProgressPopup({ isShow: true, time: 0, title:data.popTitle }));
      await new Promise((resolve) => setTimeout(resolve, 500));

      let resultImgUrls = generations.generation_output_ids.map((el) => {
        return el.output_url;
      });

      return { generationJob, generations, results: resultImgUrls, isRetry };
    } catch (error) {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      throw new Error(error);
    } finally {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
    }
  }
);

export const createMediaThunk = createAsyncThunk(
  "solutions/createMediaThunk",
  async (data, thunkAPI) => {
    try {
      const media = await customAPI(
        "post",
        `/media`,
        { ...data },
        {
          dispatch: thunkAPI.dispatch,
          // disableLoader: true,
          loaderMsg: "Uploading image",
        }
      );

      let mediaId = media._id;
      if(data.solution_name!=="VIRTUAL_STAGING" && data.solution_name!=="EMPTY_YOUR_SPACE" && data.solution_name!=="RENDER_EXTERIOR_STRUCTURES"){
      await customAPI(
        "patch",
        `/media/mask/${mediaId}`,
        { url: data.url },
        {
          dispatch: thunkAPI.dispatch,
          disableLoader: true,
          disableSnackbar: true,
        }
      );
      }
      return { mediaId };
    } catch (error) {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      throw new Error(error);
    } finally {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
    }
  }
);

export const getMediaSolutionsApiThunk = createAsyncThunk(
  "solutions/getMediaSolutionsApiThunk",
  async (data, thunkAPI) => {
    try {
      let solution = data?.solution || "";
      let projectId = data?.projectId || "";
      let mediaId = data?.mediaId || "";
      let generationId = data?.generationId || "";
      let auto_mask_exclusion_filter =
        data.auto_mask_exclusion_filter ||
        SOLUTIONS_SCHEMA?.[solution]?.auto_mask_exclusion_filter;
      let is_404 = false;
      
      let project_name = "";
      let pre_fetch_mask = SOLUTIONS_SCHEMA?.[solution]?.["pre_fetch_mask"] || "";
      let get_static_mask = SOLUTIONS_SCHEMA?.[solution]?.["get_static_mask"] || "";

      // if (!solution_id) {
      //   return { is_404: true };
      // }

      const media = await customAPI(
        "get",
        `/media/${mediaId}`,
        {},
        {
          dispatch: thunkAPI.dispatch,
          // disableLoader: true,
          loaderMsg: "Fetching media",
        }
      );
      let solution_id = media.solution_name
      
      let sidePanelValues = buisnessToViewTransformer(solution_id);

      project_name = media?.project_id?.name || project_name;
      if (
        (media?.solution_name && media?.solution_name !== solution_id) ||
        (media?.project_id?._id &&
          projectId &&
          media?.project_id?._id != projectId)
      ) {
        return { is_404: true };
      }
      let urlSolution = SOLUTION_SCHEMA_MAPPING[solution_id]
      // let mask = await mergeMaskImages(
      //   media?.mask_output,
      //   media?.space_type,
      //   SOLUTIONS_SCHEMA?.[urlSolution]?.auto_mask_exclusion_filter,
      // );
      
      let img = media?.url;
      let mask_output = media?.mask_output;
      let space_type = media?.space_type;
      let theme = "";
      let mask_url = media.combined_mask_url;
      let npy_url = "";
      let mask_type = media?.mask_type;
      let is_furnished = media?.is_furnished;

      if (generationId && media?.generation_request_ids.length > 0) {
        is_404 = true;
        for (const obj of media?.generation_request_ids) {
          if (generationId === obj._id) {
            Object.keys(sidePanelValues).forEach((key) => {
              if (key === "select_customization") {
                // only for kitchen cabinets
                if (obj["generation_input"]["change_type"] === "Style") {
                  sidePanelValues[key] = obj["generation_input"][
                    "material_type"
                  ]
                    ? "Material"
                    : "Color";
                }
              } else {
                sidePanelValues[key] = obj["generation_input"][key];
              }
            });

            mask_url = obj.mask_url[0];
            space_type = obj.space_type;
            theme = obj.design_theme;
            is_404 = false;
            break;
          }
        }
      }

      // if (!mask_type && !pre_fetch_mask && get_static_mask) {
      //   thunkAPI.dispatch(showLoading({ message: "Analyzing mask..." }));
      //   mask_url = await getStaticWhiteMask(img);
      //   mask_url = mask_url.url;
      //   if (generationId && media?.generation_request_ids.length > 0) {
      //     is_404 = true;
      //     for (const obj of media?.generation_request_ids) {
      //       if (generationId === obj._id) {
      //         Object.keys(sidePanelValues).forEach((key) => {
      //           if (key === "select_customization") {
      //             // only for kitchen cabinets
      //             if (obj["generation_input"]["change_type"] === "Style") {
      //               sidePanelValues[key] = obj["generation_input"][
      //                 "material_type"
      //               ]
      //                 ? "Material"
      //                 : "Color";
      //             }
      //           } else {
      //             sidePanelValues[key] = obj["generation_input"][key];
      //           }
      //         });

      //         mask_url = obj.mask_url[0];
      //         space_type = obj.space_type;
      //         theme = obj.design_theme;
      //         is_404 = false;
      //         break;
      //       }
      //     }
      //   }
      // } else if (mask_type) {
      //   npy_url = mask_output.length === 1 ? mask_output[0].url : "";
      //   if (generationId && media?.generation_request_ids.length > 0) {
      //     is_404 = true;
      //     for (const obj of media?.generation_request_ids) {
      //       if (generationId === obj._id) {
      //         Object.keys(sidePanelValues).forEach((key) => {
      //           if (key === "select_customization") {
      //             // only for kitchen cabinets
      //             if (obj["generation_input"]["change_type"] === "Style") {
      //               sidePanelValues[key] = obj["generation_input"][
      //                 "material_type"
      //               ]
      //                 ? "Material"
      //                 : "Color";
      //             }
      //           } else {
      //             sidePanelValues[key] = obj["generation_input"][key];
      //           }
      //         });

      //         mask_url = obj.mask_url[0];
      //         space_type = obj.space_type;
      //         theme = obj.design_theme;
      //         is_404 = false;
      //         break;
      //       }
      //     }
      //   } else if (!npy_url && mask_output.length > 1) {
      //     let mask = await mergeMaskImages(
      //       mask_output,
      //       space_type,
      //       auto_mask_exclusion_filter
      //     );
      //     mask_url = mask.url;
      //   } else if (mask_output.length === 0 && media?.project_id) {
      //     // poll for mask
      //     thunkAPI.dispatch(showLoading({ message: "Fetching masks..." }));
      //     const t1 = performance.now();
      //     const getMask = async () => {
      //       try {
      //         const response = await customAPI(
      //           "get",
      //           `/media/mask/${mediaId}`,
      //           {},
      //           {
      //             dispatch: thunkAPI.dispatch,
      //             disableLoader: true,
      //           }
      //         );
      //         const t2 = performance.now();
      //         if (t2 - t1 > MASK_FETCH_TIMEOUT) {
      //           thunkAPI.dispatch(hideLoading({}));
      //           thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      //           thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
      //           return;
      //         }
      //         if (response) {
      //           if (response.job_status === "error") {
      //             thunkAPI.dispatch(hideLoading({}));
      //             thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      //             thunkAPI.dispatch(
      //               openSnackbar({
      //                 message:
      //                   "Something went wrong. Please try again E-code(FME)",
      //                 status: "error",
      //               })
      //             );
      //             return;
      //           }
      //           if (response.job_status === "done") {
      //             return response; // Return the success response
      //           }
      //         }
      //       } catch (error) {
      //         throw new Error(error);
      //       }

      //       // Retry after the specified interval
      //       await new Promise((resolve) => setTimeout(resolve, 3000));
      //       return getMask(); // Recursive call
      //     };

      //     const masks = await getMask();
      //     npy_url =
      //       masks.mask_output.length === 1 ? masks.mask_output[0].url : "";

      //     if (!npy_url) {
      //       // let selectedMask = "";
      //       mask_url = await mergeMaskImages(
      //         masks.mask_output,
      //         space_type,
      //         auto_mask_exclusion_filter
      //       );
      //       mask_url = mask_url.url;
      //     }
      //   }
      // }

      if (is_404) {
        return { is_404 };
      }

      if (!generationId) {
        return {
          sidePanelValues,
          solution: SOLUTION_SCHEMA_MAPPING[solution_id],
          project_name,
          projectId:media?.project_id?._id,
          mediaId,
          generationId,
          img,
          mask_url,
          space_type,
          theme,
          npy_url,
          is_404,
          is_furnished,
        };
      }

      const generation = await customAPI(
        "get",
        `/generate/${generationId}`,
        {},
        {
          dispatch: thunkAPI.dispatch,
          // disableLoader: true,
          loaderMsg: "Getting designs",
        }
      );

      let job_status = media.job_status;

      // if (job_status !== "done") {
      //   thunkAPI.dispatch(
      //     openSnackbar({
      //       message: "This media has not been processed",
      //       status: "error",
      //     })
      //   );
      //   return;
      // }

      let results = generation.generation_output_ids.map((el) => el.output_url);
      return {
        sidePanelValues,
        solution:SOLUTION_SCHEMA_MAPPING[solution_id],
        project_name,
        projectId : media?.project_id?._id,
        mediaId,
        generationId,
        img,
        results,
        mask_url,
        space_type,
        theme,
        generations: generation,
        npy_url,
        is_404,
        is_furnished,
      };
    } catch (error) {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      if (error.response.status === 404) {
        return { is_404: true };
      } else {
        throw new Error(error);
      }
    } finally {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
    }
  }
);

// VS EMPTY ROOM
export const createGenerationVS_EmptyThunk = createAsyncThunk(
  "solutions/createGenerationVS_EmptyThunk",
  async (data, thunkAPI) => {
    try {
      const isRetry = data.isRetry || false;
      const space_type = data.space_type;
      const solution_name = data.solution_name;
      const mediaId = data.media_id;
      const uploadUrl = data.url;
      const existingGenId = data.generation_id || "";
      const auto_mask_exclusion_filter = data.auto_mask_exclusion_filter;

      let preProcessId = "";
      let vs_render_url = "";
      let maskUrl = "";

      let bodyObj = viewToBuisnessTransformer(solution_name, data);

      thunkAPI.dispatch(
        isShowProgressPopup({
          isShow: true,
          time: null,
        })
      );

      // thunkAPI.dispatch(showLoading({ message: "Generating designs..." }));

      // get media
      const media = await customAPI(
        "get",
        `/media/${mediaId}`,
        {},
        {
          dispatch: thunkAPI.dispatch,
          disableLoader: true,
          loaderMsg: "Fetching media...",
        }
      );

      let isCallPreProcess = false;
      if (existingGenId && media?.generation_request_ids.length > 0) {
        for (const obj of media?.generation_request_ids) {
          if (existingGenId === obj._id) {
            bodyObj.url = obj.url;
            bodyObj.mask_url = obj.mask_url[0];
            for (const key in obj.generation_input) {
              if (
                key === "designing_for" &&
                obj.generation_input[key] !== bodyObj.generation_input[key]
              ) {
                isCallPreProcess = true;
                break;
              }
            }
            break;
          }
        }
      } else {
        isCallPreProcess = true;
      }

      if (isCallPreProcess) {
        let eta = 90;
        thunkAPI.dispatch(
          isShowProgressPopup({
            isShow: true,
            time: eta,
            desc: "This may take up to 90 seconds.",
          })
        );
        // Preprocessing
        const createPreProcessData = await customAPI(
          "post",
          `/media/preProcess/${mediaId}`,
          {
            space_type: bodyObj.space_type,
            generation_input: {
              design_theme: bodyObj.generation_input.design_theme,
            },
          },
          {
            dispatch: thunkAPI.dispatch,
            disableLoader: true,
            loaderMsg: "Pre process...",
          }
        );
        preProcessId = createPreProcessData._id;

        // get pre processing polling
        // thunkAPI.dispatch(showLoading({ message: "Polling pre process..." }));
        const t1 = performance.now();
        const getPreProcess = async () => {
          try {
            const response = await customAPI(
              "get",
              `/media/preProcess/${preProcessId}`,
              {},
              {
                dispatch: thunkAPI.dispatch,
                disableLoader: true,
              }
            );
            const t2 = performance.now();
            if (t2 - t1 > 240000) {
              thunkAPI.dispatch(hideLoading({}));
              thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
              thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
              return;
            }
            if (response) {
              if (response.status === "error") {
                thunkAPI.dispatch(hideLoading({}));
                thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
                thunkAPI.dispatch(
                  openSnackbar({
                    message:
                      "Something went wrong. Please try again E-code(MPP)",
                    status: "error",
                  })
                );
                return;
              }
              if (response.status === "done") {
                return response; // Return the success response
              }
            }
          } catch (error) {
            throw new Error(error);
          }

          // Retry after the specified interval
          await new Promise((resolve) => setTimeout(resolve, 5000));
          return getPreProcess(); // Recursive call
        };

        const preProcess = await getPreProcess();

        const maskJob = await customAPI(
          "patch",
          `/media/mask/${mediaId}`,
          { url: uploadUrl },
          {
            dispatch: thunkAPI.dispatch,
            loaderMsg: "Creating masks",
            disableLoader: true,
          }
        );

        // get mask
        // thunkAPI.dispatch(showLoading({ message: "Fetching masks..." }));
        const t3 = performance.now();
        const getMask = async () => {
          try {
            const response = await customAPI(
              "get",
              `/media/mask/${mediaId}`,
              {},
              {
                dispatch: thunkAPI.dispatch,
                disableLoader: true,
              }
            );
            const t4 = performance.now();
            if (t4 - t3 > MASK_FETCH_TIMEOUT) {
              thunkAPI.dispatch(hideLoading({}));
              thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
              thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
              return;
            }
            if (response) {
              if (response.job_status === "error") {
                thunkAPI.dispatch(hideLoading({}));
                thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
                thunkAPI.dispatch(
                  openSnackbar({
                    message:
                      "Something went wrong. Please try again E-code(FME)",
                    status: "error",
                  })
                );
                return;
              }
              if (response.job_status === "done") {
                return response; // Return the success response
              }
            }
          } catch (error) {
            throw new Error(error);
          }

          // Retry after the specified interval
          await new Promise((resolve) => setTimeout(resolve, 3000));
          return getMask(); // Recursive call
        };

        const masks = await getMask();

        vs_render_url = masks.vs_render_url;
        bodyObj.url = vs_render_url;

        if (masks.mask_output.length === 0) {
          maskUrl = await getStaticWhiteMask(bodyObj.url);
        } else {
          maskUrl = await mergeMaskImages(
            masks.mask_output,
            space_type,
            auto_mask_exclusion_filter
          );
        }

        maskUrl = maskUrl.url;

        bodyObj.mask_url = maskUrl;
      }

      // generate

      const generationJob = await customAPI("post", `/generate`, bodyObj, {
        dispatch: thunkAPI.dispatch,
        disableLoader: true,
        loaderMsg: "Generating design",
      });

      let generationId = generationJob._id;
      if (!isCallPreProcess) {
        let eta = generationJob.eta;
        thunkAPI.dispatch(isShowProgressPopup({ isShow: true, time: eta }));
      }

      // thunkAPI.dispatch(showLoading({ message: "Generating designs..." }));
      const t5 = performance.now();
      const getGenerations = async () => {
        try {
          const response = await customAPI(
            "get",
            `/generate/${generationId}`,
            {},
            {
              dispatch: thunkAPI.dispatch,
              disableLoader: true,
            }
          );
          const t6 = performance.now();
          if (t6 - t5 > GENERATION_FETCH_TIMEOUT) {
            thunkAPI.dispatch(hideLoading({}));
            thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
            thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
            return;
          }
          if (response) {
            if (response.job_status === "error") {
              thunkAPI.dispatch(hideLoading({}));
              thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
              thunkAPI.dispatch(
                openSnackbar({
                  message: "Something went wrong. Please try again E-code(GE)",
                  status: "error",
                })
              );
              return;
            }
            if (response.job_status === "done") {
              return response; // Return the success response
            }
          }
        } catch (error) {
          throw new Error(error);
        }

        // Retry after the specified interval
        await new Promise((resolve) => setTimeout(resolve, 3000));
        return getGenerations(); // Recursive call
      };

      const generations = await getGenerations();

      thunkAPI.dispatch(isShowProgressPopup({ isShow: true, time: 0 }));
      await new Promise((resolve) => setTimeout(resolve, 500));

      let resultImgUrls = generations.generation_output_ids.map((el) => {
        return el.output_url;
      });

      return {
        generationJob,
        generations,
        results: resultImgUrls,
        isRetry,
        maskUrl,
      };
    } catch (error) {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
      throw new Error(error);
    } finally {
      thunkAPI.dispatch(hideLoading({}));
      thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
    }
  }
);

export const getMaskThunk = createAsyncThunk(
  "solutions/getMaskThunk",async (data,thunkAPI)=>{
    try
    {
      thunkAPI.dispatch(showLoading({ message: "Loading" }));
      const t1 = performance.now();
      const getMask = async () => {
        try {
          const response = await customAPI(
            "get",
            `/media/mask/${data.mediaId}`,
            {},
            {
              dispatch: thunkAPI.dispatch,
              disableLoader: true,
            }
          );
          const t2 = performance.now();
          if (t2 - t1 > MASK_FETCH_TIMEOUT) {
            thunkAPI.dispatch(hideLoading({}));
            thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
            thunkAPI.dispatch(isShowTimeoutPopup({ isShow: true }));
            return;
          }
          if (response) {
            if (response.job_status === "error") {
              thunkAPI.dispatch(hideLoading({}));
              thunkAPI.dispatch(isShowProgressPopup({ isShow: false }));
              thunkAPI.dispatch(
                openSnackbar({
                  message: "Something went wrong. Please try again E-code(FME)",
                  status: "error",
                })
              );
              return;
            }
            if (response.job_status === "done") {
              thunkAPI.dispatch(hideLoading({}));
              return response; // Return the success response
            }
          }
        } catch (error) {
          throw new Error(error);
        }

        // Retry after the specified interval
        await new Promise((resolve) => setTimeout(resolve, 2000));
        return getMask(); // Recursive call
      };

      const masks = await getMask();
      let npy_url =
        masks.mask_output.length === 1 ? masks.mask_output[0].url : "";

      let maskUrl = "";

      if (!npy_url) {
        // let selectedMask = "";
        // maskUrl = await mergeMaskImages(
        //   masks.mask_output,
        //   data.space_type,
        //   data.auto_mask_exclusion_filter
        // );
        maskUrl = masks.combined_mask_url;
      }
      return {
        mediaId:data.mediaId,
        maskUrl
      };
    }
    catch{

    }
  }
)

const solutionSlice = createSlice({
  name: "solutions",
  initialState,
  reducers: {
    setProjectName: (state, action) => {
      state.data.project_name = action.payload.projectName;
    },
    setPanelValues: (state, action) => {
      const { type, value } = action.payload;
      
      if (state?.data?.side_panel_schema?.[type]) {
        state.data.side_panel_schema[type]["value"] = value;
      }
    },
    setEnableMask: (state, action) => {
      const { value } = action.payload;
      state.data.side_panel_enabled = value;
    },
    setMaskedkUrl: (state, action) => {
      const { url } = action.payload;
      state.data.masked_url = url;
      state.page_transition.mask.save = true;
    },
    setUploadImgAndSpaceType: (state, action) => {
      const { url, spaceType, mediaId } = action.payload;
      state.data.img = url;
      state.data.media_id = mediaId;
      state.data.space_type = spaceType;
      if (state.data.side_panel_schema["designing_for"]) {
        for (const obj of state.data.side_panel_schema["designing_for"][
          "options"
        ]) {
          if (obj.value === spaceType) {
            state.data.side_panel_schema["designing_for"]["value"] = spaceType;
            state.data.side_panel_schema["designing_for"]["temp_value"] =
              spaceType;
            break;
          }
        }
      }
    },
    resetPageTransitions: (state, action) => {
      state.page_transition = {
        upload: { uploaded: false },
        projects: { created: false },
        dp: { generation: false },
        mask: { save: false },
        results: { generation: false },
        view_results: { variations: false, redesign: false },
        not_found: { is_404: false },
      };
    },
    setSolutionSchema: (state, action) => {
      const { solutionName } = action.payload;
      state.data = JSON.parse(JSON.stringify(SOLUTIONS_SCHEMA[solutionName]));
    },
    setIsFavorite: (state, action) => {
      state.data.generations_data[action.payload.generationId].is_favourite =
        action.payload.favorite;
    },
    setSpaceType: (state, action) => {
      
      state.data.space_type = action.payload.space_type;
    },
    setIsFurnished: (state, action) => {
      state.data.is_furnished = action?.payload?.is_furnished;
      state.previousFurnishedStatus.is_furnished = action.payload?.is_furnished;
    },
    resetPreviousFurnishedStatus: (state) => {
      state.previousFurnishedStatus = {
        is_furnished: "NA",
        solution_id: "NA",
      };
    },
    setPreviousFurnishedStatus: (state, action) => {
      state.previousFurnishedStatus = {
        is_furnished: action.payload.is_furnished,
        solution_id: action.payload?.solution_id,
      };
    },
    setPreviousSolution: (state, action) => {
      state.previousFurnishedStatus.solution_id = action.payload?.solution_id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewProjectThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.project_id = action.payload.projectId;
      state.data.media_id = action.payload.mediaId;

      // state.data.is_furnished = action?.payload?.is_furnished;

      if (action.payload.npy_url) {
        state.data.npy_url = action.payload.npy_url;
      } else if (action.payload.maskUrl) {
        state.data.masked_url = action.payload.maskUrl;
      }

      if (action.payload.designFurther) {
        state.page_transition.view_results.redesign = true;
      } else {
        state.page_transition.projects.created = true;
      }
    });
    builder.addCase(getMaskThunk.fulfilled, (state, action) => {
      state.loading = false;
      
      state.data.media_id = action?.payload?.mediaId;
      state.data.masked_url = action.payload.maskUrl;
    });
    builder.addCase(createGenerationThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data.generation_id = action.payload.generationJob._id;
      Object.keys(state.data.side_panel_schema).forEach((key) => {
        state.data.side_panel_schema[key]["temp_value"] =
          state.data.side_panel_schema[key]["value"];
      });
      state.data.generations_data =
        action.payload.generations.generation_output_ids;
      state.data.results = [...action.payload.results];
      if (!action.payload.isRetry) {
        state.page_transition.dp.generation = true;
        state.page_transition.view_results.variations = true;
      }
    });
    builder.addCase(createMediaThunk.fulfilled, (state, action) => {
      state.loading = false;
      
      state.data.media_id = action.payload.mediaId;
      state.page_transition.upload.uploaded = true;
    });
    builder.addCase(
      createGenerationVS_EmptyThunk.fulfilled,
      (state, action) => {
        state.loading = false;
        state.data.generation_id = action.payload.generationJob._id;
        Object.keys(state.data.side_panel_schema).forEach((key) => {
          state.data.side_panel_schema[key]["temp_value"] =
            state.data.side_panel_schema[key]["value"];
        });
        state.data.masked_url = action.payload.maskUrl;
        state.data.generations_data =
          action.payload.generations.generation_output_ids;
        state.data.results = action.payload.results;
        if (!action.payload.isRetry) {
          state.page_transition.dp.generation = true;
          state.page_transition.view_results.variations = true;
        }
      }
    );
    builder.addCase(getMediaSolutionsApiThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.page_transition.not_found.is_404 =
        action.payload.is_404 || state.page_transition.not_found.is_404;
      if (SOLUTIONS_SCHEMA?.[action.payload.solution]) {
        state.data = JSON.parse(
          JSON.stringify(SOLUTIONS_SCHEMA?.[action.payload.solution])
        );
        state.previousFurnishedStatus.solution_id =
          REVERSE_SOLUTION_SCHEMA_MAPPING[action.payload.solution];
      }
      
      Object.keys(action?.payload?.sidePanelValues || {})?.forEach((key) => {
        if (
          key === "color_and_pattern_preference" &&
          action.payload.sidePanelValues[key]?.split(",").length > 0 &&
          state.data.side_panel_schema?.[
            "multiple_color_and_pattern_preference"
          ]
        ) {
          state.data.side_panel_schema[
            "multiple_color_and_pattern_preference"
          ].value = action.payload.sidePanelValues[key];
        } else {
          if (state.data.side_panel_schema?.[key]) {
            state.data.side_panel_schema[key].value =
              action.payload.sidePanelValues[key];
          }
        }
      });

      
      Object.keys(state?.data?.side_panel_schema)?.forEach((key) => {
        state.data.side_panel_schema[key]["temp_value"] =
          state?.data?.side_panel_schema[key]["value"];
      });

      state.data.project_name =
        action.payload.project_name || state.data.project_name;
      state.data.project_id = action.payload.projectId || state.data.project_id;
      state.data.media_id = action.payload.mediaId || state.data.media_id;
      state.data.is_furnished = action?.payload?.is_furnished;
      state.data.generation_id =
        action.payload.generationId || state.data.generation_id;
      state.data.img = action?.payload?.img || state.data.img;
      state.data.space_type =
        action?.payload?.space_type || state.data.space_type;
      state.data.npy_url = action?.payload?.npy_url || state.data.npy_url;
      state.data.masked_url =
        action?.payload?.mask_url || state.data.masked_url;
      state.previousFurnishedStatus.is_furnished =
        action?.payload?.is_furnished;
      if (action?.payload?.generations) {
        state.data.generations_data =
          action?.payload?.generations?.generation_output_ids ||
          state.data.generations_data;
      }
      if (action?.payload?.results) {
        state.data.results = action?.payload?.results || state.data.results;
      }
      // state.data.side_panel_schema.room_type.value = action.payload.space_type;
      // state.data.side_panel_schema.theme_type.value = action.payload.theme;
    });
  },
});

export const {
  setProjectName,
  setPanelValues,
  setEnableMask,
  setUploadImgAndSpaceType,
  resetPageTransitions,
  setMaskedkUrl,
  setSolutionSchema,
  setIsFavorite,
  setSpaceType,
  setIsFurnished,
  resetPreviousFurnishedStatus,
  setPreviousFurnishedStatus,
  setPreviousSolution,
} = solutionSlice.actions;

export default solutionSlice.reducer;

// export const fetchSomeData = createAsyncThunk(
//   "solutions/fetch",
//   async (data, thunkAPI) => {
//     const response = await customAPI(
//       "get",
//       `https://fakestoreapi.com/products`,
//       {},
//       {
//         dispatch: thunkAPI.dispatch,
//         loaderMsg: "loading your data please wait",
//         disableLoader: false,
//         disableSnackbar: false,
//       }
//     );
//     return response;
//   }
// );
