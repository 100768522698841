import axios from "axios";
import { readFileAsBinary } from "./mediaFile";
import { BACKEND_URL } from "@/ui-constants";
import { rotateImage } from "./mediaFile";
import { presigned2Cdn } from "@/utils/transformer";
import auth from "./auth";
export const uploadImageAPINoStore = async (
  file,
  mediaFolder = "media",
  roationAngle = 360,
) => {
  try {
    const idToken = await auth();
    roationAngle != 360 && (file = await rotateImage(file, roationAngle));
    const getPresignedUrl = await axios.request({
      method: "post",
      url: `${BACKEND_URL}/media/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": idToken,
      },
      data: {
        fileName: file.name.replace(/[\s%+&=?#]/g, "_"),
        contentType: file.type,
        mediaFolder,
      },
    });

    
    
    const img_url = await axios.request({
      method: "put",
      maxBodyLength: Infinity,
      url: getPresignedUrl.data,
      headers: {
        "Content-Type": "image/jpeg",
        // "Authorization": idToken,
      },
      data: await readFileAsBinary(file),
    });
    let presigned2CdnObj = presigned2Cdn(getPresignedUrl.data, mediaFolder);
    if (!presigned2CdnObj) {
      throw new Error("Something went wrong while getting the url E-code(PUE)");
    }
    return presigned2CdnObj;
  } catch (err) {
    throw new Error(err);
  }
};
