import axios from "axios";
import { requestHandler, successHandler, errorHandler } from "./interceptor";
import { BACKEND_URL } from "@/ui-constants";

import auth from "@/utils/auth";

export const customAPI = async (method, url, data = null, config) => {
  const idToken = await auth();
  //add your BASE_URL to Constants file
  const axiosInstance = axios.create({
    baseURL: BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
      "Authorization": idToken, 
    },
  });
  // Handle request process
  axiosInstance.interceptors.request.use((request) =>
    requestHandler(request, config)
  );
  // Handle response process
  axiosInstance.interceptors.response.use(
    (response) => successHandler(response.data, config),
    (error) => errorHandler(error, config)
  );

  const response = await axiosInstance({
    method,
    url,
    data,
  });
  return response;
};
