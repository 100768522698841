import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timeoutPopup: { isShow: false },
  progressPopup: {
    isShow: false,
    time: "",
    title: "",
    desc: "",
  },
};

const commonPopupSlice = createSlice({
  name: "commonPopup",
  initialState,
  reducers: {
    isShowTimeoutPopup: (state, action) => {
      const { isShow } = action.payload;
      state.timeoutPopup.isShow = isShow;
    },
    isShowProgressPopup: (state, action) => {
      const { time, title, desc,isShow } = action.payload;
      state.progressPopup.isShow = isShow;
      state.progressPopup.time = time;
      state.progressPopup.title = title;
      state.progressPopup.desc = desc;
    },
  },
});

export const { isShowTimeoutPopup, isShowProgressPopup } =
  commonPopupSlice.actions;

export default commonPopupSlice.reducer;
