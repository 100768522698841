import dynamic from "next/dynamic";
export const readFileAsBinary = (file) => {
  try {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const binaryData = new Uint8Array(event.target.result);
        resolve(binaryData);
      };

      reader.onerror = function (event) {
        reject(new Error("Error reading the file as binary."));
      };

      reader.readAsArrayBuffer(file);
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const rotateImage = async (file, degree=360)=> {
  try {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async function (event) {
        const img = new Image();

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const radians = (degree * Math.PI) / 180;

          const width =
            Math.abs(Math.cos(radians) * img.width) +
            Math.abs(Math.sin(radians) * img.height);
          const height =
            Math.abs(Math.sin(radians) * img.width) +
            Math.abs(Math.cos(radians) * img.height);

          canvas.width = width;
          canvas.height = height;

          ctx.translate(width / 2, height / 2);
          ctx.rotate(radians);
          ctx.drawImage(img, -img.width / 2, -img.height / 2);

          canvas.toBlob((blob) => {
            const rotatedFile = new File([blob], file.name, {
              type: file.type,
            });
            resolve(rotatedFile);
          }, file.type);
        };

        img.src = event.target.result;
      };

      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  } catch (err) {
    throw new Error(err);
  }
}

export const convertHeicToPng = async (heicFile) => {
  try {
    const heic2any = (await import("heic2any")).default;
    const heicBlob = new Blob([heicFile], { type: "image/heic" });
    const pngBlob = await heic2any({
      blob: heicBlob,
      toType: "image/png",
    });
    return new File([pngBlob], `${heicFile.name.split('.')[0]}.png`, { type: "image/png"});
  } catch (err) {
    throw new Error(err);
  }
};

export const isImgURLValid = async(url) => {
  try {
    const response = await fetch(url);
    return (
      response.ok && response.headers.get("Content-Type").startsWith("image/")
    );
  } catch (error) {
    return false;
  }
}

export const resizeImage = async (file) =>{
  return new Promise(async (resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
         const maxWidth = 2048;
         const maxHeight = 2048;
         const minWidth = 512;
         const minHeight = 512;
         let newWidth = img.width;
         let newHeight = img.height;

         // Resize logic
         if (img.width > maxWidth || img.height > maxHeight) {
           const aspectRatio = img.width / img.height;
           if (img.width > maxWidth) {
             newWidth = maxWidth;
             newHeight = maxWidth / aspectRatio;
           }
           if (newHeight > maxHeight) {
             newHeight = maxHeight;
             newWidth = maxHeight * aspectRatio;
           }
         } else if (img.width < minWidth || img.height < minHeight) {
           const aspectRatio = img.width / img.height;
           if (img.width < minWidth) {
             newWidth = minWidth;
             newHeight = minWidth / aspectRatio;
           }
           if (newHeight < minHeight) {
             newHeight = minHeight;
             newWidth = minHeight * aspectRatio;
           }
         }

          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            });

            resolve(resizedFile);
          }, file.type);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
}



