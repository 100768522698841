import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isActive: false,
  backLink:"",
  title:"",
  ctaType:"",
  ctaText:"",
  varient:"",
  confirmBackPrompt:false,
  projectId:"",
};

const topbarSlice = createSlice({
  name: "topbar",
  initialState,
  reducers: {
    showTopbar: (state, action) => {
        return {...action.payload,isActive:true};
    },
    hideTopbar: (state) => {
      return initialState;
    },
  },
});

export const { showTopbar, hideTopbar } = topbarSlice.actions;

export default topbarSlice.reducer;
